<template>
  <div class="footer">
    <div class="inner">
      <div class="top">
        <div class="company">
          <img src="../../assets/img/logo.png" alt="" class="img" />
          <div class="info">
            {{ t("footer.companyInfo") }}
          </div>
          <div class="info1">{{ t("footer.companyInfo1") }}:&nbsp;&nbsp;&nbsp;545366243</div>
          <div class="info3">
            <span class="address"> {{ t("footer.companyInfo3") }}: </span>
            <span class="address-text">
              {{ t("footer.companyAddress") }}
            </span>
          </div>
          <div class="info4">{{ t("footer.companyInfo4") }}</div>
        </div>
        <div class="column">
          <div class="column-list">
            <div class="title">{{ t("footer.company") }}</div>
            <div class="desc" @click="navigateTo('/aboutUs')">
              {{ t("footer.aboutUs") }}
            </div>
            <div class="desc" @click="navigateTo('/aboutUs')">
              {{ t("footer.contactUs") }}
            </div>
            <div class="desc" @click="navigateTo('/aboutUs')">
              {{ t("footer.joinUs") }}
            </div>
          </div>
          <div class="column-list">
            <div class="title">{{ t("footer.game") }}</div>
            <div class="desc" @click="handleToGameList('recommend')">
              {{ t("footer.topGames") }}
            </div>
            <div class="desc" @click="handleToGameList('new')">
              {{ t("footer.newGamesRecomand") }}
            </div>
            <div class="desc">
              <el-tooltip class="box-item" effect="dark" content="敬请期待..." placement="top">
                {{ t("footer.gameRanking") }}
              </el-tooltip>
            </div>
          </div>
          <div class="column-list">
            <div class="title">
              {{ t("footer.comunity") }}
            </div>
            <div class="desc">
              <el-tooltip class="box-item" effect="dark" content="敬请期待..." placement="top">
                {{ t("footer.playerForum") }}
              </el-tooltip>
            </div>
            <div class="desc">
              <el-tooltip class="box-item" effect="dark" content="敬请期待..." placement="top">
                {{ t("footer.gameStrategy") }}
              </el-tooltip>
            </div>
            <div class="desc">
              <el-tooltip class="box-item" effect="dark" content="敬请期待..." placement="top">
                {{ t("footer.playerShare") }}
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="focus-us">
          <div class="online-service">
            <div class="text">{{ t("footer.onlineServiceText") }}</div>
            <div class="btn">
              <el-button class="btn1" @click="navigateTo('/gameServerList')">{{ t("footer.onlineServiceBtn")
                }}</el-button>
            </div>
          </div>
          <div class="title">{{ $t("footer.focusUs") }}</div>
          <div class="img">
            <img src="@/assets/img/footer-pic/focus-1.png" alt="" />
            <img src="@/assets/img/footer-pic/focus-2.png" alt="" />
            <img src="@/assets/img/footer-pic/focus-3.png" alt="" />
            <img src="@/assets/img/footer-pic/focus-4.png" alt="" />
            <img src="@/assets/img/footer-pic/focus-5.png" alt="" />
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="item1">{{ $t("footer.copyright") }}</div>
        <div class="item2">
          <div class="left">
            <img :src="gonguan" alt="" />
            <span>{{ $t("footer.icp") }}</span>
          </div>
          <div class="right">
            <img :src="gonguan" alt="" />
            <span>{{ $t("footer.publicNet") }}</span>
          </div>
        </div>
        <div class="item3">
          <span @click="
            navigateTo({
              path: '/newsCenter',
              query: {
                type: 'document',
              },
            })
            ">{{ $t("footer.termsOfService") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import gonguan from "@/assets/img/footer-pic/gonguan.png"
import { useI18n } from "vue-i18n"

const router = useRouter()

const { t } = useI18n()

const handleToGameList = (val) => {
  navigateTo({
    path: "/gameServerList",
    query: {
      arrange: val,
    },
  })
}
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #222326;
  height: 374px;
  width: 100%;

  &::before {
    content: "";
    width: 440px;
    height: 300px;
    position: absolute;
    left: 75px;
    z-index: 1;
    opacity: 0.5;
    background-image: url("../../assets/img/bg.png");
    background-size: 140%;
    transform: rotate(10deg);
    background-position: 50%, 50%;
  }

  .inner {
    width: 1300px;
    margin-top: 20px;

    .top {
      display: flex;
      width: 100%;
      height: 284px;
      color: white;

      .company {
        display: flex;
        flex-direction: column;
        width: 350px;
        height: 100%;

        img {
          width: 174.43px;
        }

        .info,
        .info1,
        .info2,
        .info3,
        .info4 {
          margin-top: 21px;
          font-size: 16px;
          width: 340px;
        }

        .info {
          line-height: 20px;
        }

        .info3 {
          display: flex;
          height: 32px;

          .address {
            margin-right: 4px;
          }

          .address-text {
            flex: 1;
          }
        }
      }

      .column {
        display: flex;
        justify-content: space-around;
        flex: 1;
        padding: 0 18px;

        .title {
          width: 36px;
          font-size: 17px;
          font-weight: 700;
          padding-bottom: 10px;
          border-bottom: 0.89px solid #eee;
        }

        .desc {
          font-size: 16px;
          margin-top: 24px;
          cursor: pointer;

          &:hover {
            color: skyblue;
          }
        }
      }

      .focus-us {
        width: 220px;
        display: flex;
        flex-direction: column;

        .online-service {
          display: flex;
          flex-direction: column;

          .text {
            font-size: 19px;
            font-weight: 700;
          }

          .btn {
            margin-top: 18px;

            .btn1 {
              background-image: linear-gradient(to right,
                  rgba(237, 31, 130, 1),
                  rgba(156, 64, 233, 1));
              border: none;
              color: #fff;
              font-size: 17px;
              width: 239.95px;
              height: 40.73px;

              &:hover {
                opacity: 0.8;
                transform: scale(1.01);
              }
            }
          }
        }

        .title {
          margin-top: 22px;
          font-size: 19px;
        }

        .img {
          margin-top: 10px;
          display: flex;

          img {
            margin: 6px;
            width: 20px;
            height: 20px;
            object-fit: cover;
            border: 1px solid #fff;
            padding: 4px;
            border-radius: 6px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      color: rgba(150, 150, 150, 1);
      width: 100%;
      height: 20px;
      padding-top: 16px;
      border-top: 2px solid #353536;

      .item1 {
        font-size: 12px;
      }

      .item2 {
        display: flex;
        // align-items: center;
        font-size: 12px;

        .right {
          margin-left: 18px;
        }

        .left,
        .right {
          img {
            position: relative;
            top: -3px;
            right: 4px;
            width: 14px;
          }
        }
      }

      .item3 {
        display: flex;
        font-size: 12px;

        span {
          display: block;
          margin-left: 18px;
          cursor: pointer;

          &:hover {
            color: skyblue;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
